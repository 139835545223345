import { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { Col, Container, Spinner, Row } from 'react-bootstrap';
import { CardContainer } from './components/containers/CardContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import NavigationBar from './components/containers/NavigationBar';
import TitleBar from './components/containers/TitleBar';
import { WaterPolo } from './models/implementations/sports/waterpolo/WaterPolo';
import { Availability } from './models/interfaces/SportProvider';
import CompetitionDashboard from './pages/competitions/CompetitionDashboard';
import ResolverView from './components/containers/ResolverView';
import EventDetailPage from './pages/events/EventDetailPage';
import { ToastProvider } from './context/useToastsContext';
import CompetitionEntryDashboard from './pages/competitionEntry/CompetitionEntryDashboard';
import UnavailablePage from './pages/LandingPage/UnavailablePage';
import MessageLabel from './components/text/MessageLabel';
import { VendorTask } from './pages/users/UserVendorPage';
import { EmbeddableEventView } from './pages/events/EventDashboard';
import styled from 'styled-components';
const ExplorePage = lazy(() => import('./pages/explore/ExplorePage'));
const UserJoinOrganization = lazy(() => import('./components/pages/UserJoinOrganization'));
const MatchDashboard = lazy(() => import('./pages/matches/MatchDashboard'));
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'));
const AuthView = lazy(() => import('./components/auth/AuthView'));
const UserOrganizationsView = lazy(() => import('./pages/organizations/UserOrganizationsView'));
const TeamDashboard = lazy(() => import('./pages/teams/TeamDashboard'));
const EventDashboard = lazy(() => import('./pages/events/EventDashboard'));
const UserEventsPage = lazy(() => import('./pages/events/UserEventsPage'));
const PlayerDashboard = lazy(() => import('./pages/players/PlayerDashboard'));
const OrganizationDashboard = lazy(() => import('./pages/organizations/OrganizationDashboard'));
const OrganizationAdminDashboard = lazy(
    () => import('./pages/organizations/OrganizationAdminDashboard')
);
const RedeemEventAdminInvitation = lazy(() => import('./pages/events/RedeemEventAdminInvitation'));
const CompetitionAdminDashboard = lazy(
    () => import('./pages/competitions/CompetitionAdminDashboard')
);
const RedeemCompetitionInvitation = lazy(
    () => import('./pages/redeemCompetitionInvitation/RedeemCompetitionInvitation')
);
const EventPaymentConfirmationPage = lazy(
    () => import('./pages/events/EventPaymentConfirmationPage')
);
const EventRegistrationPage = lazy(
    () => import('./pages/events/registration/EventRegistrationPage')
);
const TeamRosterAdminPage = lazy(() => import('./pages/teams/roster/TeamRosterAdminPage'));
const PublicUserPage = lazy(() => import('./pages/users/PublicUserPage'));
const RedeemClaimPlayerInvitationPage = lazy(
    () => import('./pages/users/RedeemClaimPlayerInvitationPage')
);
const UserVendorPage = lazy(() => import('./pages/users/UserVendorPage'));
const CreateEventPage = lazy(() => import('./pages/events/admin/CreateEventPage'));
const ValidateCreateEventPage = lazy(() => import('./pages/events/admin/ValidateCreateEventPage'));
const EventAdminPage = lazy(() => import('./pages/events/admin/EventAdminPage'));
const PrivateUserPage = lazy(() => import('./pages/users/PrivateUserPage'));

const sportProvider = WaterPolo;

function AnalyticsComponent() {
    let location = useLocation();
    useEffect(() => {
        if (location) {
            const path = location.pathname + location.search;
            sportProvider.analytics.logPageView(path);
        }
    }, [location]);
    return <></>;
}

const StyledContainer = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 500px;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

const ContentView = styled.div`
    flex-grow: 1;
`;

function App() {
    const [availabilty, setAvailabiltiy] = useState<Availability | undefined>(undefined);
    const [availabiltyResolver] = useState(
        sportProvider.updatingResolverProvider.updatingAvailabiltiyResolver()
    );

    useEffect(() => {
        const token = availabiltyResolver.addListener((update) => {
            if (typeof update === 'string') {
            } else {
                setAvailabiltiy(update);
            }
        });
        return () => {
            availabiltyResolver.cancel(token);
        };
    }, [availabiltyResolver]);

    if (!!availabilty?.isHidden) {
        return (
            <>
                <UnavailablePage />
            </>
        );
    }
    return (
        <Router>
            <Switch>
                <Route path="/embeddable" component={EmbeddableContainer} />
                <Route component={AppContainer} />
            </Switch>
        </Router>
    );
}

function AppContainer() {
    const [user, setUser] = useState(sportProvider.authProvider.currentUser);
    useEffect(() => {
        sportProvider.authProvider.onUpdateCurrentUser(setUser);
    }, [sportProvider.authProvider]);

    return (
        <StyledContainer>
            <AnalyticsComponent />
            <ToastProvider>
                <NavigationBar />
                <ContentView>
                    <Suspense fallback={<Spinner animation="border" />}>
                        <Route exact path="/redeemCompetitionInvitation">
                            <CardContainer>
                                {user ? (
                                    <RedeemCompetitionInvitation
                                        user={user}
                                        sportProvider={sportProvider}
                                    />
                                ) : (
                                    <CardContainer>
                                        <MessageLabel>
                                            Please log in to redeem the invitation.
                                            <br />
                                        </MessageLabel>
                                        <p>
                                            With your account, you'll be able to save your roster
                                            for other competitions.
                                        </p>
                                        <AuthView authProvider={sportProvider.authProvider} />
                                    </CardContainer>
                                )}
                            </CardContainer>
                        </Route>
                        <Route exact path="/matches/:matchID">
                            <MatchDashboard
                                user={user}
                                sportProvider={sportProvider}
                                resolverProvider={sportProvider.updatingResolverProvider}
                            />
                        </Route>
                        <Route path="/eventPaymentComplete/:eventID" exact>
                            <CardContainer>
                                {user ? (
                                    <EventPaymentConfirmationPage
                                        user={user}
                                        sportProvider={sportProvider}
                                    />
                                ) : (
                                    <AuthView
                                        authProvider={sportProvider.authProvider}
                                        message="You are trying to access a page that requires a logged in user."
                                    />
                                )}
                            </CardContainer>
                        </Route>
                        <Route path="/eventRegistration/:eventID" exact>
                            {user ? (
                                <EventRegistrationPage user={user} sportProvider={sportProvider} />
                            ) : (
                                <CardContainer>
                                    <AuthView
                                        authProvider={sportProvider.authProvider}
                                        message="You must be logged in to register for an event"
                                    />
                                </CardContainer>
                            )}
                        </Route>
                        <Route path="/redeemEventAdminInvitation" exact>
                            <CardContainer>
                                {user ? (
                                    <RedeemEventAdminInvitation
                                        user={user}
                                        sportProvider={sportProvider}
                                    />
                                ) : (
                                    <AuthView
                                        authProvider={sportProvider.authProvider}
                                        message="You are trying to access a page that requires a logged in user. If you were provided a link please log in or create a new user with the same email address and retry the link"
                                    />
                                )}
                            </CardContainer>
                        </Route>
                        <Route path="/redeem">
                            <CardContainer>
                                {user ? (
                                    <UserJoinOrganization user={user} />
                                ) : (
                                    <AuthView
                                        authProvider={sportProvider.authProvider}
                                        message="The route you are trying to access requires a logged in user. If you were provided a link please log in or create a new user with the same email address and retry the link"
                                    />
                                )}
                            </CardContainer>
                        </Route>
                        <Route exact path="/teams/:teamID">
                            <TeamDashboard user={user} sportProvider={sportProvider} />
                        </Route>
                        <Route exact path="/teams/:teamID/rosterAdmin">
                            {user ? (
                                <TeamRosterAdminPage user={user} sportProvider={sportProvider} />
                            ) : (
                                <AuthView
                                    authProvider={sportProvider.authProvider}
                                    message="The route you are trying to access requires a logged in user. If you were provided a link please log in or create a new user with the same email address and retry the link."
                                />
                            )}
                        </Route>
                        <Route exact path="/organizations/:organizationID">
                            <OrganizationDashboard user={user} sportProvider={sportProvider} />
                        </Route>
                        <Route exact path="/organizations/:organizationID/admin">
                            {user ? (
                                <OrganizationAdminDashboard
                                    user={user}
                                    sportProvider={sportProvider}
                                />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path={'/competitions/:competitionID'}>
                            <CompetitionDashboard user={user} sportProvider={sportProvider} />
                        </Route>
                        <Route exact path={'/competitions/:competitionID/admin'}>
                            {user ? (
                                <CompetitionAdminDashboard
                                    user={user}
                                    sportProvider={sportProvider}
                                />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        {/* page is similar to team dashboard but for a singular competition */}
                        <Route exact path="/competitions/:competitionID/entries/:entryID">
                            <CompetitionEntryDashboard user={user} sportProvider={sportProvider} />
                        </Route>
                        <Route exact path={'/players/:playerID'}>
                            <PlayerDashboard user={user} sportProvider={sportProvider} />
                        </Route>
                        <Route exact path="/events/:eventID/admin">
                            {user ? (
                                <EventAdminPage user={user} sportProvider={sportProvider} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path="/events/:eventID">
                            <EventDashboard user={user} sportProvider={sportProvider} />
                        </Route>
                        <Route path="/myevents">
                            {user ? (
                                <UserEventsPage user={user} sportProvider={sportProvider} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path="/createEvent">
                            {user ? (
                                <CreateEventPage user={user} sportProvider={sportProvider} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path="/eventCreationComplete">
                            {user ? (
                                <ValidateCreateEventPage user={user} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>

                        <Route path="/myorganizations">
                            {user ? (
                                <UserOrganizationsView user={user} sportProvider={sportProvider} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path={'/users/:userID'}>
                            <PublicUserPage user={user} sportProvider={sportProvider} />
                        </Route>
                        <Route exact path={'/vendor/'}>
                            {user ? (
                                <UserVendorPage user={user} task={VendorTask.view} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path={'/verifyVendor/'}>
                            {user ? (
                                <UserVendorPage user={user} task={VendorTask.verifyAccount} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path={'/refreshVendorOnboarding/'}>
                            {user ? (
                                <UserVendorPage user={user} task={VendorTask.refreshLinks} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path="/leagues">
                            <ResolverView
                                resolver={sportProvider.eventResolver('CowtownStampede')}
                                itemTranslator={(cowtownStampede) => {
                                    return (
                                        <EventDetailPage
                                            user={user}
                                            event={cowtownStampede}
                                            sportProvider={sportProvider}
                                        />
                                    );
                                }}
                            />
                        </Route>
                        <Route exact path={'/redeemClaimPlayerInvitation/:invitationID'}>
                            {user ? (
                                <RedeemClaimPlayerInvitationPage user={user} />
                            ) : (
                                <CardContainer>
                                    <AuthView authProvider={sportProvider.authProvider} />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path="/myaccount">
                            {user ? (
                                <PrivateUserPage user={user} sportProvider={sportProvider} />
                            ) : (
                                <CardContainer>
                                    <AuthView
                                        authProvider={sportProvider.authProvider}
                                        message="The route you are trying to access requires a logged in user."
                                    />
                                </CardContainer>
                            )}
                        </Route>
                        <Route exact path="/">
                            <LandingPage sportProvider={sportProvider} />
                        </Route>
                    </Suspense>
                </ContentView>
            </ToastProvider>
        </StyledContainer>
    );
}

function EmbeddableContainer() {
    return (
        <Suspense fallback={<Spinner animation="border" />}>
            <Route exact path={'/embeddable/events/:eventID'}>
                <EmbeddableEventView sportProvider={sportProvider} />
            </Route>
            <Route exact path={'/embeddable/waterpolo_australia'}>
                <ExplorePage
                    sportProvider={sportProvider}
                    teamCategory={sportProvider.teamCategories[1]}
                    preselectedDivisions={sportProvider.teamCategories[1].divisions}
                    preselectedGenders={sportProvider.teamGenders}
                />
            </Route>
        </Suspense>
    );
}

export default App;

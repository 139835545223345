import { useState, useMemo, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { User, Name } from '../../models/interfaces/User';
import UpcomingMatchesView from '../matches/UpcomingMatchesView';
import { SportProvider } from '../../models/interfaces/SportProvider';
import CompletedMatchesView from '../competitions/CompletedMatchesView';
import PageHeader, { LinkPropsArray } from '../../components/containers/PageHeader';
import {
    CompetitionEntry,
    CompetitionEntryAdmin,
} from '../../models/interfaces/Competitions/CompetitionEntry';
import useListProviderToArray, {
    useUpdatableProviderToArray,
} from '../../helpers/useListProviderToArray';
import { isMatch } from '../../models/interfaces/Match';
import {
    isRosterEntry,
    RosterEntry,
    CapNumber,
    Position,
} from '../../models/interfaces/RosterEntry';
import TeamRosterView from '../teams/TeamRosterView';
import CompetitionEntryRosterForm from './CompetitionEntryRosterForm';

interface Props {
    user?: User;
    competitionEntry: CompetitionEntry;
    sportProvider: SportProvider;
}

export default function CompetitionEntryDetailPage({
    user,
    competitionEntry,
    sportProvider,
}: Props) {
    const [competitionEntryAdmin, setCompetitionEntryAdmin] = useState<
        CompetitionEntryAdmin | undefined
    >(undefined);
    const [addingPlayer, setAddingPlayer] = useState<boolean>(false);

    const matches = useListProviderToArray({
        provider: competitionEntry.matchProvider,
        isType: isMatch,
    });
    const memorizedRosterSort = useCallback((a: RosterEntry, b: RosterEntry) => {
        if (a.isArchived && !b.isArchived) return 1;
        if (!a.isArchived && b.isArchived) return -1;
        if (a.capNumber.sortValue < b.capNumber.sortValue) return -1;
        if (a.capNumber.sortValue > b.capNumber.sortValue) return 1;
        return 0;
    }, []);
    const { state: teamPlayers, triggerUpdate: triggerUpdatedPlayerList } =
        useUpdatableProviderToArray({
            provider: competitionEntry.rosterProvider,
            isType: isRosterEntry,
            sortFn: memorizedRosterSort,
        });

    //if an array order is: competition -> competitionEntry
    const title = useMemo(() => {
        return [competitionEntry.competition.attributes.name, competitionEntry.name];
    }, [competitionEntry]);

    const backingTeam = competitionEntryAdmin?.competitionEntry.backingTeam;

    const linkObject: LinkPropsArray[] = useMemo(() => {
        const competitionLink: LinkPropsArray = {
            section: 'title',
            href: `/competitions/${competitionEntry.competition.id}`,
            titleIdx: 0,
        };
        const backingTeamLink: LinkPropsArray = {
            section: 'title',
            href: competitionEntry.backingTeam?.id
                ? `/teams/${competitionEntry.backingTeam?.id}`
                : `/competitions/${competitionEntry.competition.id}`,
            titleIdx: 1,
        };

        return [competitionLink, backingTeamLink];
    }, [competitionEntry.backingTeam?.id, competitionEntry.competition.id]);

    const subTitleString = useMemo(() => {
        const attributes = competitionEntry.competition.attributes;
        return [sportProvider.displayTextForTeamOrCompetitionAttributes(attributes)];
    }, [competitionEntry.competition.attributes, sportProvider]);

    useEffect(() => {
        if (user) {
            competitionEntry.adminForUser(user).then((admin) => {
                setCompetitionEntryAdmin(admin);
            });
        }
    }, [competitionEntry, user]);

    const [showRosterModal, setShowRosterModal] = useState(false);

    return (
        <Container className="mb-4 pt-1">
            <PageHeader title={title} link={linkObject} subTitleArr={subTitleString} />
            <UpcomingMatchesView matches={matches} showCompetition={false} />
            <Row className="justify-content-around pt-4">
                <Col xs={12} md={5} xl={4} className="offset-md-1 offset-xl-2">
                    <CompletedMatchesView matches={matches} showCompetition={false} />
                </Col>
                <Col xs={12} md={5} xl={4}>
                    <TeamRosterView teamPlayers={teamPlayers} />
                    {competitionEntryAdmin && (
                        <>
                            <Button className="btn-block" onClick={() => setShowRosterModal(true)}>
                                Manage Roster
                            </Button>
                            <Modal show={showRosterModal} onHide={() => setShowRosterModal(false)}>
                                <Modal.Header closeButton>
                                    <Col>
                                        <Row>
                                            <h2>Roster Management</h2>
                                        </Row>
                                    </Col>
                                </Modal.Header>
                                <Modal.Body>
                                    <CompetitionEntryRosterForm
                                        sportProvider={sportProvider}
                                        competitionEntryAdmin={competitionEntryAdmin}
                                        playerList={teamPlayers}
                                    />
                                </Modal.Body>
                            </Modal>
                        </>
                    )}
                </Col>
                {/* used to counter the offset in first col as there is no offset left */}
                <Col xs={0} md={1} xl={2}></Col>
            </Row>
        </Container>
    );
}
